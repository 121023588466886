import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { FooterAlternate1 } from "../../components/footer"
import SEO from "../../components/seo"

const IndexPage = ({ location }) => {
    const [video, setVideo] = useState(true);
    const [article, setArticle] = useState(true);
    const [filter, setFilter] = useState(0);
 return (
 <Layout location={location}> 
    <SEO title="Insights" />

    <div className="articles">
        <div className="articles__search search-filters">
            <div className="container">
                <div className="row">
                    <div className="col-lg-offset-1 col-lg-7">
                        <form>
                            <h3 className="search-filters__title">Find insights on...</h3>
                            <div>
                                <div className="wrap">
                                    <select data-placeholder="Everything" onChange={({ target }) => setFilter(target.selectedIndex)} style={{fontSize: '36px', fontWeight: 'bold', border: 'none', borderBottom: '2px solid #72ad33', background: 'none'}}>
                                        <option value="Everything">Everything</option>
                                        <option value="B2B Sales">B2B Sales</option>
                                        <option value="Sales careers">Sales careers</option>
                                        <option value="Career change">Career change</option>
                                    </select>
                                </div>
                            </div>
                            <div className="search-filters__filters">
                                <div className="checkbox-group inline">
                                    
                                    <div className="checkbox-button">
                                        <input type="checkbox" value="articles" name="types" id="articles-filter" checked={article} onChange={() => setArticle(!article)} />
                                        <label htmlFor="articles-filter">Articles</label>
                                    </div>
                                    <div className="checkbox-button">
                                        <input type="checkbox" value="videos" name="types" id="videos-filter" checked={video} onChange={() => setVideo(!video)} />
                                        <label htmlFor="videos-filter">Videos</label>
                                    </div>
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div className="articles__list container">
            <div className="articles__row row">
                
        { ((filter === 0 || filter === 2) && article) &&     
        <>  
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/7-laws-sales-success" className="card card--article">
                <div className="card__thumb">
                    <img alt="article-ideas-announcement.jpg" className="card__image" height="404" src="../../media/images/article-ideas-announcement_6oOJESq.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">Sales careers</p>
                        <h3 className="card__title"><strong>The 7 Laws of Sales Success</strong></h3>
                    </header>
                    <p>There are certain &quot;Laws&quot; of the universe which successful people often leverage, and today&#39;s&#39; sales people must learn to embrace some of these important laws to ensure future sales success.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/sales-outreach-destroying-your-career" className="card card--article">
                <div className="card__thumb">
                    <img alt="article-challenge-yell.jpg" className="card__image" height="404" src="../../media/images/article-challenge-yell_YbkW7pV.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">Sales careers</p>
                        <h3 className="card__title"><strong>Is sales Outreach destroying your Career?</strong></h3>
                    </header>
                    <p>If you are still performing 20th century outreach tactics like &quot;cold calling&quot; then you are not just destroying your own career, but you are also destroying your employers brand and therefore their business.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }

        { ((filter === 0 || filter === 1) && article) &&  
        <>  
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/sales-training-complete-waste-money-and-time-part-2" className="card card--article">
                <div className="card__thumb">
                    <img alt="article-connect-coffee-chat.jpg" className="card__image" height="404" src="../../media/images/article-connect-coffee-chat_xr8wJT.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Sales Training’ is a waste of money and time (Part 2)</strong></h3>
                    </header>
                    <p>We must stop teaching sales people how to ‘push’ buyers, and instead start training and coaching them on how to become highly specialised teachers.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/sales-training-waste-time-and-money-part-1" className="card card--article">
                <div className="card__thumb">
                    <img alt="article-connect-coffee-chat.jpg" className="card__image" height="404" src="../../media/images/article-connect-coffee-chat_xr8wJT.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>‘Sales Training’ is a waste of time and money (Part 1)</strong></h3>
                    </header>
                    <p>When you teach sales people how to ‘push’ buyers through a sales playbook you are teaching them how to destroy your business reputation.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }
        { ((filter === 0 || filter === 2) && article) && 
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/extrovert-introvert-rethinking-sales-priorities" className="card card--article">
                <div className="card__thumb">
                    <img alt="article-challenge-juggle.jpg" className="card__image" height="404" src="../../media/images/article-challenge-juggle_PUKUKDN.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">Sales careers</p>
                        <h3 className="card__title"><strong>From extrovert to introvert: Rethinking sales priorities</strong></h3>
                    </header>
                    <p>What is the profile of a modern sales person?

This article was written by Graham Hawkins and it first appeared on the Salesforce Blog in July 2018:</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }
        { ((filter === 0 || filter === 1) && video) &&  
        <>  
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/b2b-sales-has-changed-about-180-degrees" className="card card--article">
                <div className="card__thumb">
                    <img alt="Mentor Large Graham Hawkins" className="card__image" height="404" src="../../media/images/Mentor-Large-Graham-Hawkins.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">04:28</span>
                    <span className="card__icon play-icon">
                        <i className="icon"></i>
                    </span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>B2B Sales has changed about 180 Degrees!</strong></h3>
                    </header>
                    <p>B2B sales has now changed so dramatically that it&#39;s a &quot;profession&quot; that is barely recognisable to the role that both Tony Hughes and Graham Hawkins began about 30 years ago - respectively.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/brief-chat-hubspot" className="card card--article">
                <div className="card__thumb">
                    <img alt="Mentor Large Graham Hawkins" className="card__image" height="404" src="../../media/images/Mentor-Large-Graham-Hawkins.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">04:05</span>
                    <span className="card__icon play-icon">
                        <i className="icon"></i>
                    </span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>A brief chat with HubSpot</strong></h3>
                    </header>
                    <p>Vendors are now being forced to give the buyer the buying experience that they now demand, and that means ceasing &amp; desisting with the outdated vendor-PUSH sales tactics.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }


        { ((filter === 0 || filter === 1) && article) &&  
        <> 
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/only-sales-model-will-exist-3-years-now" className="card card--article">
                <div className="card__thumb">
                    <img alt="article-ideas-cogs.jpg" className="card__image" height="404" src="../../media/images/article-ideas-cogs_DKg13MP.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">6 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>The only sales model that will exist in 3 years from now.</strong></h3>
                    </header>
                    <p>The change that we are now witnessing in buyer tastes and preferences means a big swing from outbound sales activities to INBOUND, and that requires vendors to remove any misalignment that may exist between your sales execution approach and what buyers now want from the buying experience.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }

        { ((filter === 0 || filter === 1) && video) &&  
        <>  
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/video-chat-deloitte" className="card card--article">
                <div className="card__thumb">
                    <img alt="Mentor Large Graham Hawkins" className="card__image" height="404" src="../../media/images/Mentor-Large-Graham-Hawkins.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">07:23</span>
                    <span className="card__icon play-icon">
                        <i className="icon"></i>
                    </span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>A video chat with Deloitte</strong></h3>
                    </header>
                    <p>Deloitte Sales Effectiveness is headed up by Mr Brandon Kulik (Principal Sales Effectiveness), and in this conversation I ask Brandon about sales related challenges that Deloitte is now helping their clients with. From educated buyers and the changing tech landscape, we are seeing more and more change in the traditional sales role. The be a solution provider you have to have a solution - and assembled solution.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }

        { ((filter === 0 || filter === 1) && article) && 
        <> 
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/sales-people-class-action-against-management" className="card card--article">
                <div className="card__thumb">
                    <img alt="article-challenge-yell.jpg" className="card__image" height="404" src="../../media/images/article-challenge-yell_YbkW7pV.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Sales people in class ACTION against Management.</strong></h3>
                    </header>
                    <p>A crimes is now being committed against sales people, and enough is enough.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
    
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/time-pressure-and-high-costs-are-killing-house-sales-team-smbs" className="card card--article">
                <div className="card__thumb">
                    <img alt="Future group direction" className="card__image" height="404" src="../../media/images/article-future-group-direction.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>High costs are killing the &#39;in-house&#39; sales team for SMB&#39;s</strong></h3>
                    </header>
                    <p>Everyone knows that cash-flow is the ‘life-blood’ of every business.....as they say, “revenue is vanity, profit is sanity, but cash is king”.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/your-sales-leader-destroying-your-business" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success dice heads" className="card__image" height="404" src="../../media/images/article-success-dice-heads.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Is your &#39;Sales Leader&#39; destroying your business?</strong></h3>
                    </header>
                    <p>The sales leader role is not only the most important, but it&#39;s also the most difficult role in every business. Sadly most sales leaders are having a horrible time right now</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }
        {/* page 2 */}

        { ((filter === 0 || filter === 2) && article) && 
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/new-golden-age-b2b-sales-has-arrived" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success golden egg" className="card__image" height="404" src="../../media/images/article-success-golden-egg.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">Sales careers</p>
                        <h3 className="card__title"><strong>The new golden age of B2B sales has arrived.</strong></h3>
                    </header>
                    <p>So much of the sales role is still lived in a fog of automatic and habitual behaviour due largely to outdated leadership that has failed to recognise this new customer-led era that we now live in.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }
        
        { ((filter === 0 || filter === 1) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/no-commissions-no-quota-future-model-sales" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success target" className="card__image" height="404" src="../../media/images/article-success-target.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">6 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>No Commissions. No Quota: The future model for sales</strong></h3>
                    </header>
                    <p>Sales quotas and commission payments drive self-centered behaviors and this is why there has always been the embedded distrust of sales people. Lots of vendors are now waking up and moving towards buyer aligned measurements and rewards...and not before time.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }
        
        { ((filter === 0 || filter === 1) && article) && 
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/your-sales-culture-killing-your-business" className="card card--article">
                <div className="card__thumb">
                    <img alt="Challenge rope pull" className="card__image" height="404" src="../../media/images/article-challenge-rope-pull.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Your sales culture is killing your business.</strong></h3>
                    </header>
                    <p>If you still measure and manage B2B sales people around sales quotas, commissions and a steadfast focus on top-line revenue attainment, then you are being surpassed by the savvy vendors whom have already woken up to the new reality.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }
        
        { ((filter === 0 || filter === 2) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/my-career-b2b-saleswhere-it-going" className="card card--article">
                <div className="card__thumb">
                    <img alt="Connect recruit" className="card__image" height="404" src="../../media/images/article-connect-recruit.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">Sales careers</p>
                        <h3 className="card__title"><strong>My career in B2B sales....where is it going?</strong></h3>
                    </header>
                    <p>If you currently work in B2B sales, then you need to begin thinking differently about your career direction and begin to plan appropriately for all of the change that is coming.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }

        { ((filter === 0 || filter === 1) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/influencing-buyer-sales-engagement-insights" className="card card--article">
                <div className="card__thumb">
                    <img alt="Connect global handshake" className="card__image" height="404" src="../../media/images/article-connect-global-handshake.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">2 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Influencing The Buyer. Sales Engagement Insights</strong></h3>
                    </header>
                    <p>It has always been important to go, and be, where your customers are... physically and online.</p>
                    <footer><p className="card__author">Tony J. Hughes</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/what-main-cause-sales-under-performance" className="card card--article">
                <div className="card__thumb">
                    <img alt="Challenge ball juggle" className="card__image" height="404" src="../../media/images/article-challenge-ball-juggle.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>What is the main cause of sales under-performance?</strong></h3>
                    </header>
                    <p>Sales people simply don&#39;t spend enough time selling.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/people-buy-people-they-know-and-trust-right-not-any-more" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success partners" className="card__image" height="404" src="../../media/images/article-success-partners.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Can you really &#39;trust&#39; a sales person?</strong></h3>
                    </header>
                    <p>People buy from people that they &quot;know, like and trust&quot;​ right? Not any more!</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }
        
        { ((filter === 0 || filter === 1) && video) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/your-target-market-getting-smaller" className="card card--article">
                <div className="card__thumb">
                    <img alt="Mentor Large Graham Hawkins" className="card__image" height="404" src="../../media/images/Mentor-Large-Graham-Hawkins.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5:06</span>
                    <span className="card__icon play-icon">
                        <i className="icon"></i>
                    </span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Your target market is getting smaller</strong></h3>
                    </header>
                    <p>Buyers need to do more business with fewer vendors…meaning that your marketing is shrinking.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }
        
        { ((filter === 0 || filter === 3) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/can-robots-and-ai-actually-help-you-enhance-your-sales-career" className="card card--article">
                <div className="card__thumb">
                    <img alt="Future robot" className="card__image" height="404" src="../../media/images/article-future-robot.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">Career change</p>
                        <h3 className="card__title"><strong>Can Robots and AI actually help you to enhance your sales career?</strong></h3>
                    </header>
                    <p>Sales people who embrace working with bots and leverage advanced technology will be well positioned to survive the big cull that his now coming.</p>
                    <footer><p className="card__author">Tony J. Hughes</p></footer>
                </div>
            </Link>
        </article>
        </>}
        
        { ((filter === 0 || filter === 1) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/5-simple-sales-strategies-2017-and-beyond" className="card card--article">
                <div className="card__thumb">
                    <img alt="Search boat" className="card__image" height="404" src="../../media/images/article-search-boat.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">6 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>5 Simple Sales Strategies for 2017, and beyond!</strong></h3>
                    </header>
                    <p>&quot;There is only one boss: the customer. And he can fire everyone in the company, simply by spending his money somewhere else&quot;</p>
                    <footer><p className="card__author">Cian Mcloughlin</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/are-you-just-same-next-sales-person" className="card card--article">
                <div className="card__thumb">
                    <img alt="Connect global handshake" className="card__image" height="404" src="../../media/images/article-connect-global-handshake.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Are you just the same as the next sales person?</strong></h3>
                    </header>
                    <p>Are you a memorable sales person?</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/are-you-focused-wrong-target-customers" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success mobile cash" className="card__image" height="404" src="../../media/images/article-success-mobile-cash.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Are you focused on the wrong target customers?</strong></h3>
                    </header>
                    <p>Why sales people MUST stop focusing on &#39;net new&#39; customers.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>

        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/do-not-call-me-sales-person-or-else" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success smash" className="card__image" height="404" src="../../media/images/article-success-smash.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Do not call me a sales person or else!</strong></h3>
                    </header>
                    <p>Why we must drop the word “sales” from all business dialogue.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/winning-sale-2017" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success partners" className="card__image" height="404" src="../../media/images/article-success-partners.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Winning the sale in 2017</strong></h3>
                    </header>
                    <p>Sales people must stop pushing…..and start using honey!</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/are-you-listening-your-buyers" className="card card--article">
                <div className="card__thumb">
                    <img alt="Connect excited people" className="card__image" height="404" src="../../media/images/article-connect-excited-people.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Are you listening to your buyers?</strong></h3>
                    </header>
                    <p>Stop listening to sales guru&#39;s and just ask your customers how they want to buy?</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }
        
        { ((filter === 0 || filter === 3) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/driving-forces-change" className="card card--article">
                <div className="card__thumb">
                    <img alt="Challenge thinker" className="card__image" height="404" src="../../media/images/article-challenge-thinker.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">Career change</p>
                        <h3 className="card__title"><strong>The driving forces of change:</strong></h3>
                    </header>
                    <p>The 5 Forces of &#39;change&#39; for Sales People</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </> }

        { ((filter === 0 || filter === 1) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/sales-people-need-kevin-bacon" className="card card--article">
                <div className="card__thumb">
                    <img alt="Connect handshake" className="card__image" height="404" src="../../media/images/article-connect-handshake.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Sales people need Kevin Bacon!</strong></h3>
                    </header>
                    <p>Kevin Bacon is helping to kill cold calling…..hallelujah!</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/too-many-sales-people-are-panning-gold" className="card card--article">
                <div className="card__thumb">
                    <img alt="Challenge reach" className="card__image" height="404" src="../../media/images/article-challenge-reach.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>The Stupidity of Prospecting</strong></h3>
                    </header>
                    <p>Sales Prospecting – are you just digging up dirt?</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/sales-leader-most-important-role-every-business" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success mountain" className="card__image" height="404" src="../../media/images/article-success-mountain.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Sales Leader – the most important role in every business.</strong></h3>
                    </header>
                    <p>Is you sales leader the most important, or the most dangerous person in your business?.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/sales-30-do-you-have-latest-tools" className="card card--article">
                <div className="card__thumb">
                    <img alt="Startup launch" className="card__image" height="404" src="../../media/images/article-startup-launch.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Sales 3.0 - do you have the latest tools?</strong></h3>
                    </header>
                    <p>Will your role survive in a digitally enabled sales force?</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }

        {/* { ((filter === 0 || filter === 1) && video) && */}
        {/* <> */}
        {/* <article className="articles__item col-sm-6 col-md-4"> */}
        {/*     <Link to="/knowledge-centre/insights/power-personal-branding" className="card card--article"> */}
        {/*         <div className="card__thumb"> */}
        {/*             <img alt="Mentor Large John Smibert" className="card__image" height="404" src="../../media/images/Mentor-Large-John-Smibert.2e16d0ba.fill-716x404.jpg" width="716"/> */}
        {/*             <span className="card__image-meta">2:22</span> */}
        {/*             <span className="card__icon play-icon"> */}
        {/*                 <i className="icon"></i> */}
        {/*             </span> */}
        {/*         </div> */}
        {/*         <div className="card__inner"> */}
        {/*             <header> */}
        {/*                 <p className="card__category">B2B Sales</p> */}
        {/*                 <h3 className="card__title"><strong>The power of personal branding</strong></h3> */}
        {/*             </header> */}
        {/*             <p>What’s the power of personal branding for you?</p> */}
        {/*             <footer><p className="card__author">John Smibert</p></footer> */}
        {/*         </div> */}
        {/*     </Link> */}
        {/* </article> */}
        {/* </>} */}
        

        {/* { ((filter === 0 || filter === 3) && video) && */}
        {/* <> */}
        {/* <article className="articles__item col-sm-6 col-md-4"> */}
        {/*     <Link to="/knowledge-centre/insights/personal-branding-case-study" className="card card--article"> */}
        {/*         <div className="card__thumb"> */}
        {/*             <img alt="Mentor Large Cian Mcloughlin" className="card__image" height="404" src="../../media/images/Mentor-Large-Cian-Mcloughlin.2e16d0ba.fill-716x404.jpg" width="716"/> */}
        {/*             <span className="card__image-meta">7:22</span> */}
        {/*             <span className="card__icon play-icon"> */}
        {/*                 <i className="icon"></i> */}
        {/*             </span> */}
        {/*         </div> */}
        {/*         <div className="card__inner"> */}
        {/*             <header> */}
        {/*                 <p className="card__category">Career change</p> */}
        {/*                 <h3 className="card__title"><strong>A personal branding case study.</strong></h3> */}
        {/*             </header> */}
        {/*             <p>You are who the internet says you are!</p> */}
        {/*             <footer><p className="card__author">Cian Mcloughlin</p></footer> */}
        {/*         </div> */}
        {/*     </Link> */}
        {/* </article> */}
        {/* </>} */}

        { ((filter === 0 || filter === 2) && video) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/are-you-ready-ride-tsunami-change-coming" className="card card--article">
                <div className="card__thumb">
                    <img alt="Mentor Large Graham Hawkins" className="card__image" height="404" src="../../media/images/Mentor-Large-Graham-Hawkins.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4:14</span>
                    <span className="card__icon play-icon">
                        <i className="icon"></i>
                    </span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">Sales careers</p>
                        <h3 className="card__title"><strong>Are you ready to ride the tsunami of change that is coming?</strong></h3>
                    </header>
                    <p>Sales people (and vendor organisations) need to transform radically to survive a tsunami of change that is coming.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>}

        { ((filter === 0 || filter === 1) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/generalists-have-no-place-sales-profession" className="card card--article">
                <div className="card__thumb">
                    <img alt="Challenge ball juggle" className="card__image" height="404" src="../../media/images/article-challenge-ball-juggle.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>‘Generalists’ have no place in the sales profession:</strong></h3>
                    </header>
                    <p>It&#39;s hardly surprising that the ‘generalists’ are now routinely being viewed by their customers as “adding no value”.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }
        {/* page 4 */}

        { ((filter === 0 || filter === 2) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/how-develop-valuable-insights-your-customers" className="card card--article">
                <div className="card__thumb">
                    <img alt="Search direction" className="card__image" height="404" src="../../media/images/article-search-direction.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">Sales careers</p>
                        <h3 className="card__title"><strong>How to develop valuable ‘insights’ for your customers.</strong></h3>
                    </header>
                    <p>Sellers need to be business improvement specialists!</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>}

        { ((filter === 0 || filter === 1) && video) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/sales-process-killing-your-results" className="card card--article">
                <div className="card__thumb">
                    <img alt="Mentor Large Graham Hawkins" className="card__image" height="404" src="../../media/images/Mentor-Large-Graham-Hawkins.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5:38</span>
                    <span className="card__icon play-icon">
                        <i className="icon"></i>
                    </span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>The sales process is killing your results.</strong></h3>
                    </header>
                    <p>Sales is no longer about the sales process!</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>
        }


        { ((filter === 0 || filter === 1) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/what-role-does-your-sales-team-now-play-value-chain" className="card card--article">
                <div className="card__thumb">
                    <img alt="Ideas cogs" className="card__image" height="404" src="../../media/images/article-ideas-cogs.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>What role does your sales team now play in the value chain?</strong></h3>
                    </header>
                    <p>Is your sales department a Wholesaler or Retailer?</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/has-your-sales-leader-gone-mad" className="card card--article">
                <div className="card__thumb">
                    <img alt="Challenge boss" className="card__image" height="404" src="../../media/images/article-challenge-boss.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Has your sales leader gone mad?</strong></h3>
                    </header>
                    <p>Is your sales leader barking mad? Or are they just ignoring the facts?</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>}
        
        { ((filter === 0 || filter === 1) && video) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/focussing-pipeline-size-mistake" className="card card--article">
                <div className="card__thumb">
                    <img alt="Mentor Large Graham Hawkins" className="card__image" height="404" src="../../media/images/Mentor-Large-Graham-Hawkins.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5:35</span>
                    <span className="card__icon play-icon">
                        <i className="icon"></i>
                    </span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Focussing on &#39;pipeline&#39; size is a mistake!</strong></h3>
                    </header>
                    <p>You are misleading yourself when you measure your pipeline!</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/first-rule-sales-make-sure-you-differentiate" className="card card--article">
                <div className="card__thumb">
                    <img alt="Mentor Large Graham Hawkins" className="card__image" height="404" src="../../media/images/Mentor-Large-Graham-Hawkins.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5:34</span>
                    <span className="card__icon play-icon">
                        <i className="icon"></i>
                    </span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Measuring pipeline drives the wrong behaviours!</strong></h3>
                    </header>
                    <p>Sales people are measured and managed by metrics that encourage and foster the wrong behaviours. Focusing on &#39;pipeline size&#39; is a big mistake.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>}
        
        { ((filter === 0 || filter === 1) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/why-cant-sales-people-sell-themselves" className="card card--article">
                <div className="card__thumb">
                    <img alt="Ideas working mind" className="card__image" height="404" src="../../media/images/article-ideas-working-mind.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Why can&#39;t sales people sell themselves?</strong></h3>
                    </header>
                    <p>Sales people are notoriously bad when it comes to selling themselves....why?</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>}
        

        { ((filter === 0 || filter === 1) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/team-composition-key-sales-success" className="card card--article">
                <div className="card__thumb">
                    <img alt="Search boat" className="card__image" height="404" src="../../media/images/article-search-boat.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Team composition is the key to sales success!</strong></h3>
                    </header>
                    <p>&#39;Team Composition&#39; is the key to driving high performing sales teams.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>}

        { ((filter === 0 || filter === 2) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/sales-still-most-important-role-every-business" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success thumbs up" className="card__image" height="404" src="../../media/images/article-success-thumbs-up.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">Sales careers</p>
                        <h3 className="card__title"><strong>Sales is still the most important role in every business.</strong></h3>
                    </header>
                    <p>Despite the conjecture, sales is still the most important role in every business.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>}

        {/* { ((filter === 0 || filter === 3) && video) && */}
        {/* <> */}
        {/* <article className="articles__item col-sm-6 col-md-4"> */}
        {/*     <Link to="/knowledge-centre/insights/sales-people-should-create-content" className="card card--article"> */}
        {/*         <div className="card__thumb"> */}
        {/*             <img alt="Mentor Large Tony Hughes" className="card__image" height="404" src="../../media/images/Mentor-Large-Tony-Hughes.2e16d0ba.fill-716x404.jpg" width="716"/> */}
        {/*             <span className="card__image-meta">3:30</span> */}
        {/*             <span className="card__icon play-icon"> */}
        {/*                 <i className="icon"></i> */}
        {/*             </span> */}
        {/*         </div> */}
        {/*         <div className="card__inner"> */}
        {/*             <header> */}
        {/*                 <p className="card__category">Career change</p> */}
        {/*                 <h3 className="card__title"><strong>Sales people should create content.</strong></h3> */}
        {/*             </header> */}
        {/*             <p>We live in the age of personal brands!</p> */}
        {/*             <footer><p className="card__author">Tony J. Hughes</p></footer> */}
        {/*         </div> */}
        {/*     </Link> */}
        {/* </article> */}
        {/* </>} */}

        { ((filter === 0 || filter === 1) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/personal-relationships-no-longer-matter-b2b-sales" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success hole jump" className="card__image" height="404" src="../../media/images/article-success-hole-jump.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Personal relationships no longer matter in B2B Sales!</strong></h3>
                    </header>
                    <p>People buy from people right? Really?</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/main-reason-cold-calling-really-bad-idea" className="card card--article">
                <div className="card__thumb">
                    <img alt="Challenge question" className="card__image" height="404" src="../../media/images/article-challenge-question.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>The main reason that cold calling is a really bad idea...</strong></h3>
                    </header>
                    <p></p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>}
        
        {/* page 5 */}

        { ((filter === 0 || filter === 3) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/there-big-fork-road-coming-sales-people" className="card card--article">
                <div className="card__thumb">
                    <img alt="Future direction" className="card__image" height="404" src="../../media/images/article-future-direction.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">Career change</p>
                        <h3 className="card__title"><strong>There is a big fork in the road coming for sales people</strong></h3>
                    </header>
                    <p>Vast numbers of sales people are about to be culled.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>}

        { ((filter === 0 || filter === 1) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/balance-between-retention-and-acquisition" className="card card--article">
                <div className="card__thumb">
                    <img alt="Connect balance" className="card__image" height="404" src="../../media/images/article-connect-balance.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>The balance between customer Retention and Acquisition.</strong></h3>
                    </header>
                    <p>Why sales people MUST start focusing on their existing customers.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>}
        
        {/* { ((filter === 0 || filter === 1) && video) && */}
        {/* <> */}
        {/* <article className="articles__item col-sm-6 col-md-4"> */}
        {/*     <Link to="/knowledge-centre/insights/power-social-b2b-sales" className="card card--article"> */}
        {/*         <div className="card__thumb"> */}
        {/*             <img alt="Mentor Large Tony Hughes" className="card__image" height="404" src="../../media/images/Mentor-Large-Tony-Hughes.2e16d0ba.fill-716x404.jpg" width="716"/> */}
        {/*             <span className="card__image-meta">5:30</span> */}
        {/*             <span className="card__icon play-icon"> */}
        {/*                 <i className="icon"></i> */}
        {/*             </span> */}
        {/*         </div> */}
        {/*         <div className="card__inner"> */}
        {/*             <header> */}
        {/*                 <p className="card__category">B2B Sales</p> */}
        {/*                 <h3 className="card__title"><strong>The power of &#39;social&#39; in B2B sales!</strong></h3> */}
        {/*             </header> */}
        {/*             <p>Social is not about selling - social is about engaging and listening.  It&#39;s about how you build your brand.</p> */}
        {/*             <footer><p className="card__author">Tony J. Hughes</p></footer> */}
        {/*         </div> */}
        {/*     </Link> */}
        {/* </article> */}
        {/* </> */}
        {/* } */}
        

        { ((filter === 0 || filter === 2) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/personal-development-now-critical-sales-people" className="card card--article">
                <div className="card__thumb">
                    <img alt="Ideas brain maze" className="card__image" height="404" src="../../media/images/article-ideas-brain-maze.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">Sales careers</p>
                        <h3 className="card__title"><strong>Personal development is now critical for sales people.</strong></h3>
                    </header>
                    <p>Sales people must URGENTLY take charge of their own self-development…..or find a new career.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>}
        

        { ((filter === 0 || filter === 1) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/-boarding-road-sales-success" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success teamwork" className="card__image" height="404" src="../../media/images/article-success-teamwork.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>On-boarding - the road to sales success.</strong></h3>
                    </header>
                    <p>Structured &#39;on-boarding&#39; for sales people will provide your business with massive ROI.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/forgotten-duty-sales-person" className="card card--article">
                <div className="card__thumb">
                    <img alt="Challenge wheel" className="card__image" height="404" src="../../media/images/article-challenge-wheel.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">3 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>The forgotten duty of a sales person!</strong></h3>
                    </header>
                    <p>Sales people are now failing to perform a critically important component of their role: the gathering of Market Intelligence (MI).</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/sales-people-dont-need-more-trainingthey-need-more-marketing" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success arrow" className="card__image" height="404" src="../../media/images/article-success-arrow.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">3 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Sales people don’t need more Training….they need more Marketing.</strong></h3>
                    </header>
                    <p>Sales &amp; Marketing alignment is now absolutely fundamental to success in 2017 and beyond.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/most-important-questions-sales-people-never-ask" className="card card--article">
                <div className="card__thumb">
                    <img alt="Connect puzzle people" className="card__image" height="404" src="../../media/images/article-connect-puzzle-people.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>The most important questions that sales people NEVER ask:</strong></h3>
                    </header>
                    <p>You are now in danger of being culled from the vendor stack, and you had better start asking the right questions or you are going to cost you employer lots of money.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/sales-robots-are-your-sales-people-coin-operated" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success piper" className="card__image" height="404" src="../../media/images/article-success-piper.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">3 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Sales robots – are your sales people &#39;coin operated&#39;?</strong></h3>
                    </header>
                    <p>Are you wondering why your sales conversion rates are low? and why you staff attrition rate is high? Perhaps it’s because your sales model is stuck in the 1980’s. Perhaps it&#39;s because you are treating your sales people like robots?</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/sales-uplift-two-simple-ways-create-sales-performance-improvement" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success mind climb" className="card__image" height="404" src="../../media/images/article-success-mind-climb.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">3 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Sales uplift - 2 simple ways to create performance improvement.</strong></h3>
                    </header>
                    <p>Some sales basics that many seem to forget!</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/why-sales-enablement-key" className="card card--article">
                <div className="card__thumb">
                    <img alt="Connect lock key" className="card__image" height="404" src="../../media/images/article-connect-lock-key.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Sales Enablement is the key:</strong></h3>
                    </header>
                    <p>If you are currently running or overseeing a B2B sales force, and finding the following challenges then you are not alone:</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>}

        {/* { ((filter === 0 || filter === 1) && video) && */}
        {/* <> */}
        {/* <article className="articles__item col-sm-6 col-md-4"> */}
        {/*     <Link to="/knowledge-centre/insights/empowering-sales-people-technology-tools" className="card card--article"> */}
        {/*         <div className="card__thumb"> */}
        {/*             <img alt="Mentor Large Tony Hughes" className="card__image" height="404" src="../../media/images/Mentor-Large-Tony-Hughes.2e16d0ba.fill-716x404.jpg" width="716"/> */}
        {/*             <span className="card__image-meta">4:00</span> */}
        {/*             <span className="card__icon play-icon"> */}
        {/*                 <i className="icon"></i> */}
        {/*             </span> */}
        {/*         </div> */}
        {/*         <div className="card__inner"> */}
        {/*             <header> */}
        {/*                 <p className="card__category">B2B Sales</p> */}
        {/*                 <h3 className="card__title"><strong>Empowering sales people with technology tools.</strong></h3> */}
        {/*             </header> */}
        {/*             <p>Insight into how the sales world will utilize emerging technology in order to improve selling capability.</p> */}
        {/*             <footer><p className="card__author">Tony J. Hughes</p></footer> */}
        {/*         </div> */}
        {/*     </Link> */}
        {/* </article> */}
        {/*     </>} */}
        {/* page 6 */}

        { ((filter === 0 || filter === 1) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/win-sale-focus-risk" className="card card--article">
                <div className="card__thumb">
                    <img alt="Challenge jump" className="card__image" height="404" src="../../media/images/article-challenge-jump.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>To win the sale – focus on &#39;risk&#39;</strong></h3>
                    </header>
                    <p>There is mounting evidence that ‘risk’ now plays the single most critical role in practically every buying decision.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>}
        
        { ((filter === 0 || filter === 2) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/role-sales-person-has-changed-forever" className="card card--article">
                <div className="card__thumb">
                    <img alt="Future key" className="card__image" height="404" src="../../media/images/article-future-key.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">Sales careers</p>
                        <h3 className="card__title"><strong>The role of the sales person has changed forever.....</strong></h3>
                    </header>
                    <p>…..because sales people no longer participate in the &quot;entire&quot; buying journey:</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>}

        { ((filter === 0 || filter === 1) && article) &&
        <>
        <article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/saying-no-customers-very-powerful" className="card card--article">
                <div className="card__thumb">
                    <img alt="Search fishing" className="card__image" height="404" src="../../media/images/article-search-fishing.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Saying NO to customers - is very powerful</strong></h3>
                    </header>
                    <p>Building pipeline coverage is a mistake….’qualifying out’ is what increases productivity.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/sales-people-are-you-headed-wrong-direction" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success arrow climb" className="card__image" height="404" src="../../media/images/article-success-arrow-climb.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">3 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Sales people  - are you headed in the wrong direction?</strong></h3>
                    </header>
                    <p>Buyers now have greater leverage than ever before - what does that mean for sales people:</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/are-your-sales-people-are-competent" className="card card--article">
                <div className="card__thumb">
                    <img alt="Success top cloud" className="card__image" height="404" src="../../media/images/article-success-top-cloud.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>Are your sales people are competent?</strong></h3>
                    </header>
                    <p>Are you assessing the right things?</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article><article className="articles__item col-sm-6 col-md-4">
            <Link to="/knowledge-centre/insights/b2b-sales-now-team-sport" className="card card--article">
                <div className="card__thumb">
                    <img alt="Connect group circle" className="card__image" height="404" src="../../media/images/article-connect-group-circle.2e16d0ba.fill-716x404.jpg" width="716"/>
                    <span className="card__image-meta">4 min read</span>
                </div>
                <div className="card__inner">
                    <header>
                        <p className="card__category">B2B Sales</p>
                        <h3 className="card__title"><strong>B2B Sales is now a Team Sport.</strong></h3>
                    </header>
                    <p>Sales teams have never really been &#39;teams&#39;……just individuals working autonomously under the same corporate structure.</p>
                    <footer><p className="card__author">Graham Hawkins</p></footer>
                </div>
            </Link>
        </article>
        </>}
              
            </div>
            <div className="load-more">
              <div className="row">
                <div className="col-sm-12">
                  
                  <button id="pagination-next" type="button" className="btn btn-link btn-lg" style={{display: 'none'}}>Load 12 more</button>
                  
                  <noscript>
                  
                      Page 1 of 6.
                  
                      <Link to="indexefb7.html?search=&amp;types=articles&amp;types=videos&amp;category=&amp;page=2">next</Link>
                  
                  </noscript>
                </div>
              </div>
            </div>
        </div>
    </div>

    <FooterAlternate1 />
</Layout>

)};

export default IndexPage;
